import { useMemo } from 'react';
import { Button, Container, ListGroup } from 'react-bootstrap';
import { useQuery } from 'react-query';
import { IJogo } from '../../Interfaces'
import useAPI from '../../Hooks/useAPI';
import { useNavigate } from 'react-router-dom';
import useAuth from '../../Hooks/useAuth';

const Dashboard = () => {
    const navigate = useNavigate();
    const API = useAPI();
    const { delAuth } = useAuth();

    const qryJogos = useQuery(
        'jogos',
        () => API.get('jogos', ''),
        {}
    );
    
    const dsJogos = useMemo(() => 
        qryJogos.isLoading || qryJogos.isError ?
            [] 
        : 
            [...qryJogos.data]
        , [qryJogos]
    );

    const handleJogo = (item: IJogo) => {
        navigate(`/${item.CD_JOGO}`);
    }

    return (
        <Container className="verticalCenter">
            <h1 className="text-center w-100">Selecione o Jogo</h1>
            <ListGroup className={"w-100"}>
                {
                    dsJogos.map((item : IJogo) => {
                        return (
                            <ListGroup.Item action key={item.CD_JOGO} onClick={() => handleJogo(item)}>
                                {item.NOME}
                            </ListGroup.Item>
                        );
                    })                
                }
            </ListGroup>

            <div className="w-100 mt-5 d-flex justify-content-md-center">
                <Button 
                    variant={"danger"} 
                    onClick={() => {delAuth()}}
                >
                    {'Sair do sistema'}
                </Button>
            </div>
        </Container>
    );
}

export default Dashboard;