import { useContext, useState } from "react";
import { Badge, Button, Container, Form, InputGroup } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import Tabela from "../../Components/table";
import CtxGerenciador from "../../Contexts/ctxGerenciador";
import useDMGerenciador from "./useDMGerenciador";
import { EAcao, ETpFiltro } from "../../Interfaces/enums";
import ModalConfirmacao from "./modal";

const Gerenciador = ({idPessoa} : any) => {
    const { idJogo } = useParams<{idJogo?: string}>();
    const [showModal, setShowModal] = useState<boolean>(false);
    const [acaoModal, setAcaoModal] = useState<EAcao>(EAcao.Confirmar);
    const ctxGerenciador = useContext(CtxGerenciador);
    const DataModule = useDMGerenciador(idJogo, idPessoa);
    const navigate = useNavigate();
    
    const qtdPagos = () : number => {
        let qtd : number = 0;
        if (DataModule.qryVisualizacao.isSuccess) {
            DataModule.qryVisualizacao.data.map((obj : any) => obj.PAGO == 1 ? qtd++ : null);
        };
        return qtd;
    };

    const onSubmitHandler = (e : any) => {
        let selecionados = [];
        if (JSON.stringify(ctxGerenciador.selecionados) !== '[]') {
            selecionados = ctxGerenciador.selecionados.map((data: any) => data.CD_CARTAO);
        };

        if (acaoModal === EAcao.Excluir) {
            DataModule.mutExcluirAposta.mutate(
                {   
                    "CD_CARTAO": JSON.stringify(selecionados)
                }
            );
        } else {
            DataModule.mutConfirmaAposta.mutate(
                {   
                    "DT_PAGAMENTO": e.DT_PAGAMENTO,//new Date(e.DT_PAGAMENTO).toISOString().slice(0, 10),
                    "HR_PAGAMENTO": e.HR_PAGAMENTO,
                    "CHAVE": e.CHAVE,
                    "PAGO": e.CONFIRMADO,
                    "CD_PONTEIRO": idPessoa,
                    "CD_CARTAO": JSON.stringify(selecionados)
                }
            );
        }
    };

    return (
        <>
            <ModalConfirmacao 
                show={showModal}
                onHide={setShowModal}
                idJogo={idJogo}
                idPessoa={idPessoa}
                onSubmitHandler={onSubmitHandler}
                acao={acaoModal}
            />

            <Container>
                <h1 className="w-100 text-right mt-3 d-flex justify-content-between">
                    <Button variant={"outline-dark"} onClick={() => {navigate(`/`)}}>{'<'}</Button>
                    {DataModule.dsDadosJogo.NOME}
                </h1>
                <div className="w-100 mb-3 d-flex flex-wrap justify-content-between">
                    <div className="w-100 my-1">
                        <InputGroup>
                            <InputGroup.Text id="filtrar">Filtrar por:</InputGroup.Text>
                            <Form.Select onChange={(e) => ctxGerenciador.setTpfiltro(e.target.value)} aria-label="" aria-describedby="tpfiltro">
                                <option value={ETpFiltro.Nome}>Nome</option>
                                <option value={ETpFiltro.Codigo}>Código</option>
                            </Form.Select>
                            <Form.Control onChange={e => ctxGerenciador.setFiltro(e.target.value)} placeholder="Filtro..." aria-describedby="filtrar"/>
                        </InputGroup>
                    </div>

                    <div className="w-100 mt-1 d-flex flex-row justify-content-around">
                        { 
                            ctxGerenciador.filtroPagos ?
                                <> 
                                    <h4>
                                        <Badge bg="warning" text="dark" pill>{`Confirmados: ${qtdPagos()}`}</Badge>
                                    </h4>

                                    <h4>
                                        <Badge bg="warning" text="dark" pill>{`Total: R$${qtdPagos() * DataModule.dsDadosJogo.VALOR_CARTAO}`}</Badge>
                                    </h4>

                                    <h4>
                                        <Badge bg="warning" text="dark" pill>{`Comissão: R$${((qtdPagos() * DataModule.dsDadosJogo.VALOR_CARTAO) * DataModule.dsDadosJogo.COMISSAO_PONTEIRO) / 100}`}</Badge>
                                    </h4>
                                </>
                            :
                                <>
                                    <h4>
                                        <Badge pill>{`Unit.: R$${DataModule.dsDadosJogo.VALOR_CARTAO}`}</Badge>
                                    </h4>

                                    <h4>
                                        <Badge pill>{`Selec.: ${ctxGerenciador.selecionados.length}`}</Badge>
                                    </h4>

                                    <h4>
                                        <Badge pill>{`A Receber: R$${ctxGerenciador.selecionados.length * DataModule.dsDadosJogo.VALOR_CARTAO}`}</Badge>
                                    </h4>
                                </>
                        }
                    </div>
                </div>

                <div className="w-100 mb-3 d-flex justify-content-between">
                    <Button 
                        variant={ctxGerenciador.filtroPagos ? "success" : "secondary"} 
                        onClick={() => {
                            ctxGerenciador.setFiltroPagos(!ctxGerenciador.filtroPagos);
                            ctxGerenciador.setSelecionados([]);
                        }}
                    >
                        {
                            ctxGerenciador.filtroPagos ?
                                `Apostas Pagas`
                            :
                                `Apostas não pagas`
                        }
                    </Button>

                    <Button 
                        variant={"danger"} 
                        disabled={ (ctxGerenciador.filtroPagos) || (JSON.stringify(ctxGerenciador.selecionados) === '[]') }
                        onClick={() => {setAcaoModal(EAcao.Excluir);setShowModal(true)}}
                    >
                        {'Excluir'}
                    </Button>

                    <Button 
                        variant={ctxGerenciador.filtroPagos ? "warning" : "primary"} 
                        onClick={() => {
                            setAcaoModal( 
                                ctxGerenciador.filtroPagos ?
                                    EAcao.Retirar
                                :
                                    EAcao.Confirmar
                                );
                            setShowModal(true)
                        }}
                        disabled={JSON.stringify(ctxGerenciador.selecionados) === '[]'}
                    >
                        {
                            ctxGerenciador.filtroPagos ?
                                `Retirar Confirmação`
                            :
                                `Confirmar`
                        }
                    </Button>
                </div>

                <Tabela 
                    dados={DataModule.dsVisualizacao} 
                    colunas={DataModule.grdVisualizacao} 
                />
            </Container>
        </>
    );
}

export default Gerenciador;