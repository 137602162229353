import { useState } from 'react';

const useAuth = () => {
    const getPhone = () => {
        const phoneString = sessionStorage.getItem('phone');
        return phoneString!;
    };

    const getIdPessoa = () => {
        const idPessoaString = sessionStorage.getItem('idPessoa');
        return idPessoaString!;
    };

    const [phone, setPhone] = useState<string>(getPhone());
    const [idPessoa, setIdPessoa] = useState<string>(getIdPessoa());

    const savePhone = (phone : string) => {
        const objPhone = phone;

        if (typeof objPhone !== 'undefined') {
            sessionStorage.setItem('phone', objPhone);
            setPhone(phone);
        }
    };

    const saveIdPessoa = (idPessoa : string) => {
        const objIdPessoa = idPessoa;

        if (typeof objIdPessoa !== 'undefined') {
            sessionStorage.setItem('idPessoa', objIdPessoa);
            setIdPessoa(idPessoa);
        }
    };

    const delAuth = () => {
        sessionStorage.removeItem('idPessoa');
        localStorage.removeItem('idPessoa');
        window.location.reload();
    };

    return {
        phone,
        setPhone: savePhone, 
        idPessoa,
        setIdPessoa: saveIdPessoa,
        delAuth,
    };
}

export default useAuth;