import { useState } from "react";

const useMask = () => {
    const [maskPhone, setMaskPhone] = useState<string>();

    const getPhone = (phone: string) : string => {
        return phone.replace(/\D/g, '');
    }

    const setPhone = (phone: any) => {
        phone = phone.replace(/\D/g, '');

        if (phone.slice(0, 1) !== '(' && phone.slice(0, 1).length !== 0) {
        phone = '(' + phone;
        }
        if (phone.slice(3, 4) !== ')' && phone.slice(3, 4).length !== 0) {
        phone = phone.slice(0, 3) + ') ' + phone.slice(3);
        }
        if (phone.slice(10, 11).length !== 0 && phone.slice(10, 11) !== '-') {
        phone = phone.slice(0, 10) + '-' + phone.slice(10);
        }
        if (phone.length > 9) {
        phone = phone.slice(0, 15);
        }
        
        return phone;
    };

    return {
        maskPhone,
        getPhone,
        setMaskPhone: setPhone
    };
}

export default useMask;