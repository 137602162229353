// import { useState } from 'react';
import { Button, Container, Form, InputGroup } from "react-bootstrap";
import PropTypes from 'prop-types';
import { Formik } from "formik";
import useMask from "../../Hooks/useMask";
import { MD5 } from "crypto-js";
import { IAuth } from "../../Interfaces";
import useAPI from "../../Hooks/useAPI";
import { useMutation } from "react-query";
import { useEffect } from "react";

const Autenticacao = ({ setIdPessoa, setPhone } : any) => {
    const formatter = useMask();
    const API = useAPI();
    const onSubmitLogin = useMutation((data : any) => API.get('autenticacao', data));

    const handleSubmit = (data: IAuth) => {
        const obj : IAuth = Object.assign({}, data);
        obj.CELULAR = formatter.getPhone(obj.CELULAR);
        obj.SENHA   = MD5(obj.SENHA).toString();
        onSubmitLogin.mutate(obj);
    };

    useEffect(() => {
        if (onSubmitLogin.isSuccess) {
            console.log(JSON.stringify(onSubmitLogin.data));
            setIdPessoa(onSubmitLogin.data['CD_PESSOA']);
        } else if (onSubmitLogin.isError) {
            alert(onSubmitLogin.error);
        };
    }, [onSubmitLogin, setIdPessoa]);

    return (
        <Container className="verticalCenter">
            <h1 className="text-center w-100">Seninha: Cambistas</h1>

            <Formik
                //validationSchema={schema}
                onSubmit={(e : IAuth) => handleSubmit(e)}
                initialValues={{
                    CELULAR: '',
                    SENHA: ''
                }}
                >
                {({
                    handleSubmit,
                    handleChange,
                    setFieldValue,
                    values,
                    touched,
                    isValid,
                    errors,
                }) => (
                    <Form  onSubmit={handleSubmit}> {/*validated={errors ? isValid : undefined}*/}
                        <Form.Group>
                            <Form.Label className="text-center w-100">Autentique-se para continuar</Form.Label>

                            <InputGroup className="mt-3" size="lg">
                                <InputGroup.Text id="cdPais">+55</InputGroup.Text>
                                <Form.Control 
                                    name={'CELULAR'}
                                    type={'text'}
                                    value={values.CELULAR}
                                    onChange={ e => setFieldValue("CELULAR", formatter.setMaskPhone(e.target.value)) }
                                    isInvalid={typeof errors === "undefined" ? !isValid : undefined} 
                                    placeholder="Celular..." 
                                />
                            </InputGroup>                    

                            <InputGroup className="mt-3" size="lg">
                                <Form.Control 
                                    name={'SENHA'}
                                    type={'password'}
                                    value={values.SENHA}
                                    onChange={handleChange}
                                    isInvalid={typeof errors === "undefined" ? !isValid : undefined} 
                                    placeholder="Senha..." 
                                />

                                <Button variant="primary" type="submit">Entrar</Button>
                            </InputGroup>
                        </Form.Group>
                    </Form>
                )}
            </Formik>
        </Container>
    );
};

export default Autenticacao;

Autenticacao.propTypes = {
    setIdPessoa: PropTypes.func.isRequired,
    setPhone: PropTypes.func.isRequired
}