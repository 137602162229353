import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import { Content, TDocumentDefinitions } from "pdfmake/interfaces";
import { IComprovante, IComprovanteDados, IComprovantes } from "../Interfaces";

const comprovantePDF = (jogos: IComprovantes, info: IComprovanteDados) => {
    pdfMake.vfs = pdfFonts.pdfMake.vfs;

    const title     : Content = [
        {
            
            text: [
                {text: `${info.TITULO}\n`, fontSize: 15, bold: true},
                {text: `${info.JOGO}`, fontSize: 10, color: 'blue', decoration: 'underline', link: `${process.env.REACT_APP_LINK_ACOMPANHAMENTO}/${info.ID_JOGO}`}
            ],            
            alignment: 'center',
            margin: [15, 20, 0, 10],
        }
    ];

    const footer    : Content = [
        {
            text: `${info.RODAPE}`,
            alignment: 'center',
            fontSize: 8
        }
    ]

    const dados   : Content = [
        {
            columns: [
                {
                    text: `Nome: ${info.NOME}
                        Qtd: ${info.QTD}`,
                    fontSize: 9,
                    alignment: 'left'
                },
                {
                    text: `
                    Valor: R$${info.VLR}`,
                    fontSize: 9,
                    alignment: 'right'
                }
            ],
        },
        {
            text: `
            Chave: ${info.CHV}`,
            fontSize: 9,
            alignment: 'center'
        },
    ];

    const dadosJogos = jogos.map((dado: IComprovante) : Content => {
        return [
            {
                margin: [0, 10, 0, 0],
                table: {
                    headerRows: 1,
                    widths: ['*', '*', '*', '*', '*', '*', '*', '*', '*', '*'],
                    body: [
                        [ 
                            {columns: [{text: dado.NOME, alignment: 'left'}, {text: dado.CD_CARTAO, alignment: 'right'}], style: 'tableHeader', fontSize: 8, colSpan: 10, bold: true},
                            {},
                            {},
                            {},
                            {},
                            {},
                            {},
                            {},
                            {},
                            {},
                        ],
                        [
                            {text: dado.N1,   fontSize: 8, colSpan: 1, rowSpan: 1},
                            {text: dado.N2,   fontSize: 8, colSpan: 1, rowSpan: 1},
                            {text: dado.N3,   fontSize: 8, colSpan: 1, rowSpan: 1},
                            {text: dado.N4,   fontSize: 8, colSpan: 1, rowSpan: 1},
                            {text: dado.N5,   fontSize: 8, colSpan: 1, rowSpan: 1},
                            {text: dado.N6,   fontSize: 8, colSpan: 1, rowSpan: 1},
                            {text: dado.N7,   fontSize: 8, colSpan: 1, rowSpan: 1},
                            {text: dado.N8,   fontSize: 8, colSpan: 1, rowSpan: 1},
                            {text: dado.N9,   fontSize: 8, colSpan: 1, rowSpan: 1},
                            {text: dado.N10,  fontSize: 8, colSpan: 1, rowSpan: 1},
                        ]
                    ]
                },
                layout: { fillColor: function (rowIndex, node, columnIndex) {
                    return (rowIndex % 2 === 0) ? '#CCCCCC' : null;
                }}
            }
        ]
    });

    // const details   : Content = [
    //     {
    //         table: {
    //             headerRows: 2,
    //             widths: ['*', '*', '*', '*', '*', '*', '*', '*', '*', '*'],
    //             body: [
    //                 [ 
    //                     {text: 'Jogador', style: 'tableHeader', fontSize: 8, colSpan: 10, bold: true},
    //                     {},
    //                     {},
    //                     {},
    //                     {},
    //                     {},
    //                     {},
    //                     {},
    //                     {},
    //                     {},
    //                 ],
    //                 [ 
    //                     {text: 'Nº 1', style: 'tableHeader', fontSize: 8, bold: true},
    //                     {text: 'Nº 2', style: 'tableHeader', fontSize: 8, bold: true},
    //                     {text: 'Nº 3', style: 'tableHeader', fontSize: 8, bold: true},
    //                     {text: 'Nº 4', style: 'tableHeader', fontSize: 8, bold: true},
    //                     {text: 'Nº 5', style: 'tableHeader', fontSize: 8, bold: true},
    //                     {text: 'Nº 6', style: 'tableHeader', fontSize: 8, bold: true},
    //                     {text: 'Nº 7', style: 'tableHeader', fontSize: 8, bold: true},
    //                     {text: 'Nº 8', style: 'tableHeader', fontSize: 8, bold: true},
    //                     {text: 'Nº 9', style: 'tableHeader', fontSize: 8, bold: true},
    //                     {text: 'Nº 10', style: 'tableHeader', fontSize: 8, bold: true}
    //                 ],
    //                 ...dadosJogos
    //             ]
    //         },
    //         layout: { fillColor: function (rowIndex, node, columnIndex) {
    //             return (rowIndex % 2 === 1 && rowIndex > 0) ? '#CCCCCC' : null;
    //         }}
    //     },
    // ];

    // const footer = (currentPage: number , pageCount: number) : Content => {
    //     return [
    //         {
    //             text: currentPage.toString() + ' / ' + pageCount.toString(),
    //             alignment: 'center',
    //             fontSize: 9,
    //             margin: [0, 10, 20, 0],
    //         }
    //     ]
    // }

    const docDefinitions : TDocumentDefinitions = {
        pageSize: {width: 226, height: 190 + (jogos.length * 36)},
        pageMargins: [10, 50 , 10 , 50], //left top right bottom

        header: [title],
        content: [dados, dadosJogos],
        footer: [footer]
    }

    pdfMake.createPdf(docDefinitions).open();
}

export default comprovantePDF;