import useMask from "./useMask";

const useWhatsapp = () => {
    const formatter = useMask();

    const sendMessage = (number: string, message: string) => {
        number = formatter.getPhone(number);
        message = new URLSearchParams(message).toString();

        window.open( 
            `https://wa.me/55${number}?text=${message}`,
            `_blank`
        );
    }

    return {
        sendMessage
    }
}

export default useWhatsapp;