import { Button, Form, Modal } from "react-bootstrap";
import useDMGerenciador from "./useDMGerenciador";
import { useContext } from "react";
import CtxGerenciador from "../../Contexts/ctxGerenciador";
import { Formik } from "formik";
import { EAcao } from "../../Interfaces/enums";

const ModalConfirmacao = ({show, onHide, idJogo, idPessoa, onSubmitHandler, acao}: any) => {
    const ctxGerenciador = useContext(CtxGerenciador);
    const DataModule = useDMGerenciador(idJogo, idPessoa);

    const validaFormaDePagamento = (eletronico: boolean, data: string, hora: string) : boolean => {
        let result : boolean = true;

        if (!ctxGerenciador.filtroPagos) {
            if (eletronico) {
                if (data === '' && hora === '') {
                    result = false;
                }
            }
        };
        return result;
    };

    return (            
        <Formik
            onSubmit={(e : any) => {
                if (e.PGTO_ELETRONICO && !ctxGerenciador.filtroPagos) {
                    onSubmitHandler(e) // Pagamento eletronico
                } else if (ctxGerenciador.filtroPagos) {
                    onSubmitHandler( // Desconfirmar
                        {
                            DT_PAGAMENTO: '',
                            HR_PAGAMENTO: '',
                            CHAVE: '0',
                            CONFIRMADO: 0
                        }
                    )
                } else {
                    onSubmitHandler(
                        { // A vista
                            DT_PAGAMENTO: '',
                            HR_PAGAMENTO: '',
                            CHAVE: '0',
                            CONFIRMADO: 1
                        }
                    )
                }
            }}
            initialValues={{
                PGTO_ELETRONICO: true,
                DT_PAGAMENTO: '',
                HR_PAGAMENTO: '',
                CHAVE: '',
                CONFIRMADO: 1
            }}
        >
            {({
                handleSubmit,
                handleChange,
                values,
            }) => (
                <Modal show={show} onHide={() => onHide(false)}>
                    <Modal.Header closeButton>
                        <Modal.Title>
                            {
                                (() => {
                                    switch (acao) {
                                        case EAcao.Confirmar: return 'Confirmação do Pagamento'
                                        case EAcao.Excluir: return 'Confirmação da Exclusão'
                                        case EAcao.Retirar: return 'Retirando Confirmação'
                                    }
                                })()
                            }
                        </Modal.Title>
                    </Modal.Header>
                    
                    <Modal.Body>
                        {
                            (() => {
                                switch (acao) {
                                    case EAcao.Confirmar: return <>
                                        Deseja confirmar os jogos selecionados? <br />
                                        {JSON.stringify(ctxGerenciador.selecionados) !== '[]' ? ctxGerenciador.selecionados[0].NOME : ''} <br />
                                        {`Qtd de cartões: ${ctxGerenciador.selecionados.length}`} <br />
                                        {`A Receber: R$${ctxGerenciador.selecionados.length * DataModule.dsDadosJogo.VALOR_CARTAO}`}
                                    </>
                                    case EAcao.Excluir: return <>
                                        Deseja excluir os cartões selecionados? A ação é irreversível.
                                        A forma de pagamento também será apagada.
                                    </>
                                    case EAcao.Retirar: return <>
                                        Deseja desconfirmar os cartões selecionados? A forma de pagamento continuará vinculada.
                                    </>
                                }
                            })()
                        }
                        
                        <Form className="mt-3" onSubmit={handleSubmit}>
                            {
                                acao !== EAcao.Excluir ?
                                    <>
                                        {
                                            !ctxGerenciador.filtroPagos ?
                                                <Form.Group>
                                                    <Form.Check
                                                        name="PGTO_ELETRONICO"
                                                        type="switch"
                                                        id="tpPagto"
                                                        label={values.PGTO_ELETRONICO ? "Pagamento Eletrônico" : "Dinheiro"}
                                                        checked={values.PGTO_ELETRONICO} 
                                                        onChange={handleChange}
                                                    />
                                                </Form.Group>
                                            :
                                                <></>
                                        }
                                        {
                                            (values.PGTO_ELETRONICO && !ctxGerenciador.filtroPagos) ?
                                                <>
                                                    <Form.Group className="mt-3" controlId="dtPagamento">
                                                        <Form.Label>Data do Pagamento*</Form.Label>
                                                        <Form.Control 
                                                            name="DT_PAGAMENTO"
                                                            type="date" 
                                                            onChange={handleChange} 
                                                            value={values.DT_PAGAMENTO.toString()}
                                                        />
                                                    </Form.Group>
                                                    <Form.Group className="mt-3" controlId="hrPagamento">
                                                        <Form.Label>Hora do Pagamento*</Form.Label>
                                                        <Form.Control 
                                                            name="HR_PAGAMENTO"
                                                            type="time"
                                                            step="1"
                                                            onChange={handleChange} 
                                                            value={values.HR_PAGAMENTO}
                                                        />
                                                    </Form.Group>
                                                    <Form.Group className="mt-3" controlId="chPagamento">
                                                        <Form.Label>Chave do Pagamento</Form.Label>
                                                        <Form.Control 
                                                            name="CHAVE"
                                                            type="text" 
                                                            onChange={handleChange} 
                                                            value={values.CHAVE}
                                                        />
                                                    </Form.Group>
                                                </>
                                            :
                                                <></>
                                        }
                                    </>
                                :
                                    <></>
                            }
                        </Form>
                    </Modal.Body>

                    <Modal.Footer>
                        <Button variant="secondary" onClick={() => onHide(false)}>
                            Fechar
                        </Button>
                        {
                            acao === EAcao.Excluir ?
                                <Button variant="danger" onClick={() => handleSubmit()}>Excluir</Button>
                            :
                                <Button variant="primary" onClick={() => handleSubmit()} disabled={!validaFormaDePagamento(values.PGTO_ELETRONICO, values.DT_PAGAMENTO, values.HR_PAGAMENTO)}>
                                    Confirmar
                                </Button>
                        }
                    </Modal.Footer>
                </Modal>
            )}
        </Formik>   
    )
}

export default ModalConfirmacao;