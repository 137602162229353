import { useQuery } from "react-query";
import useAPI from "./useAPI";
import { useMemo } from "react";
import { IJogo } from "../Interfaces";

const useJogo = (idJogo: string) => {
    const API = useAPI();

    const qryDadosJogo = useQuery(
        'dadosJogo',
        () => API.get('jogo', {"cd": idJogo}),
        {}
    );

    const dsDadosJogo = useMemo<IJogo>(() => 
        qryDadosJogo.isLoading || qryDadosJogo.isError ?
            {
                "CD_JOGO": "",
                "NOME": "",
                "VALOR_CARTAO": 0,
                "OBS": "",
                "STATUS": "",
                "CONTATO": "",
                "COMISSAO_PONTEIRO": 0
            }
        :
            qryDadosJogo.data
    , [qryDadosJogo]);

    return {
        dados: dsDadosJogo
    }
}

export default useJogo;