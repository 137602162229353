import { createContext } from "react";
import { ETpFiltro } from "../Interfaces/enums";

export interface ICtxGerenciador {
    selecionados      : any;
    setSelecionados?  : any;
    tpFiltro          : string;
    setTpfiltro?      : any;
    filtro            : string;
    setFiltro?        : any;
    filtroPagos       : boolean;
    setFiltroPagos?   : any;
};

const defaultValue = {
    selecionados      : [],
    tpFiltro          : ETpFiltro.Nome,
    filtro            : '',
    filtroPagos       : false,
    vlrCartao         : 0
}

const CtxGerenciador = createContext<ICtxGerenciador>(defaultValue);

export default CtxGerenciador;