import { useState } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import CtxGerenciador from "./Contexts/ctxGerenciador";
import useAuth from "./Hooks/useAuth";
import { ETpFiltro } from "./Interfaces/enums";

import Autenticacao from "./Pages/Auth";
import Comprovante from "./Pages/Comprovante";
import Dashboard from "./Pages/Dashboard";
import Gerenciador from './Pages/Gerenciador';

const Rotas = () => {
    const {phone, setPhone, idPessoa, setIdPessoa} = useAuth();

    const [ selecionados, setSelecionados ] = useState([]);
    const [ tpFiltro, setTpfiltro ] = useState<string>(ETpFiltro.Nome);
    const [ filtro, setFiltro ] = useState<string>('');
    const [ filtroPagos, setFiltroPagos ] = useState<boolean>(false);

    if (!phone! && !idPessoa!) {
        return (
            <Autenticacao setIdPessoa={setIdPessoa} setPhone={setPhone} />       
        );
    };

    return (
        <BrowserRouter>
            <Routes>
                <Route path={'/'} element={<Dashboard />} />
                <Route path={'/comprovante/:idJogo/:idPessoa'} element={
                    <Comprovante />
                }/>
                <Route path={'/:idJogo'} element={
                    <CtxGerenciador.Provider value={{
                        selecionados,
                        setSelecionados,
                        tpFiltro,
                        setTpfiltro,
                        filtro, 
                        setFiltro,
                        filtroPagos,
                        setFiltroPagos
                    }}>
                        <Gerenciador idPessoa={idPessoa} />
                    </CtxGerenciador.Provider> 
                }/>
            </Routes>
        </BrowserRouter>
    );
}

export default Rotas;