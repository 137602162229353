import { Button, Container } from "react-bootstrap";
import { useQuery } from "react-query";
import useAPI from "../../Hooks/useAPI";
import { useEffect, useMemo, useState } from "react";
import { IComprovantes } from "../../Interfaces";
import { useNavigate, useParams } from "react-router-dom";
import useJogo from "../../Hooks/useJogo";
import { MD5 } from "crypto-js";
import comprovantePDF from "../../Reports/comprovante";
import useWhatsapp from "../../Hooks/useWhatsapp";
import useConfiguracao from "../../Hooks/useConfiguracao";

const Comprovante = () => {
    const API = useAPI();
    const whatsapp = useWhatsapp();
    const navigate = useNavigate();
    const [info, setInfo] = useState<any>({});
    const [txtWhatsapp, setTxtWhatsapp] = useState<any>('');
    const { idJogo, idPessoa } = useParams<{idJogo?: string, idPessoa?: string}>();
    const Jogo = useJogo(idJogo!);
    const Configuracao = useConfiguracao();

    const qryDados = useQuery<IComprovantes>(
        'cartoesComprovante',
        () => API.get(
            'cartoesComprovante',
            {
                "CD_JOGO": idJogo,
                "CD_PESSOA": idPessoa
            }
        ),
        {}
    );

    const dsDados = useMemo(() => 
        qryDados.isLoading || qryDados.isError ?
            []
        :
            qryDados.data
    , [qryDados]);

    useEffect(() => {
        if (JSON.stringify(dsDados) !== '[]') {
            setInfo(
                {
                    ID_JOGO: idJogo,
                    TITULO: Configuracao.dados.TITULO,
                    NOME: dsDados![0].NOME,
                    JOGO: Jogo.dados.NOME,
                    CELULAR: dsDados![0].CELULAR,
                    QTD: dsDados!.length,
                    VLR: dsDados!.length * Jogo.dados.VALOR_CARTAO,
                    CHV: MD5(idJogo + 'W' + idPessoa).toString().toUpperCase(),
                    RODAPE: Configuracao.dados.CPV_RODAPE
                }
            );
            
            setTxtWhatsapp(
                `[${info.TITULO}]

${info.NOME} sua participação no ${info.JOGO} foi confirmada.

Chave da sua participação: ${info.CHV} 

Dentro de 24 horas sua(s) aposta(s) será(ão) inserida(s) na página de acompanhamento: ${process.env.REACT_APP_LINK_ACOMPANHAMENTO}/${idJogo}

Em alguns instantes vou encaminhar o seu comprovante com os cartões para você conferir sua aposta.`
            );
        }
    }, [dsDados, setInfo, Jogo.dados, idJogo, idPessoa, Configuracao.dados, info]);

    return (    
        <Container className="verticalCenter">
            {JSON.stringify(dsDados) !== '[]' ? 
                <>
                    <h3 className="w-100 text-center mt-3">Resumo da Confirmação</h3>
                    <p className="text-center w-100">
                        {`Nome: ${JSON.stringify(info.NOME)}`} <br />
                        {`Cartões pagos: ${info.QTD} - R$${info.VLR}`} <br />
                        {`Chave: ${info.CHV}`}
                    </p>
                    {/* <Button variant="success" className="m-3" onClick={() => whatsapp.sendMessage(info.CELULAR, txtWhatsapp)}>Informar Whatsapp</Button> */}
                    <Button variant="warning" className="m-3" onClick={() => comprovantePDF(dsDados!, info)}>Imprimir PDF</Button>
                    <Button variant="danger" className="m-3" onClick={() => navigate(`/${idJogo}`)}>Voltar ao Gerenciador</Button>
                </>
            :
                <></>
            }
        </Container>
    )
}

export default Comprovante;