import { useGlobalFilter, useSortBy, useTable } from 'react-table';
import { Table } from 'react-bootstrap';

const Tabela = ({colunas, dados, selected, onSelectedRows} : any)  => {
    const { 
        getTableProps,
        getTableBodyProps, 
        headerGroups, 
        rows, 
        prepareRow
    } = useTable(
        { columns: colunas, data: dados },
        useGlobalFilter, 
        useSortBy
    );

    return(
        <>
            <Table striped bordered hover className='tabela' {...getTableProps()}>
                <thead>{headerGroups.map((headerGroup) => (
                    <tr {...headerGroup.getHeaderGroupProps()}>
                        {headerGroup.headers.map((column) => (
                            <th {...column.getHeaderProps()}>
                                { column.render("Header") }
                            </th>
                        ))}
                    </tr>
                ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                    {rows.map((row) => {
                        prepareRow(row);
                        return (
                            <tr {...row.getRowProps()}>
                                {row.cells.map((cell) => {
                                    return (
                                        <td {...cell.getCellProps()}>{ cell.render("Cell") }</td>
                                    )
                                })}
                            </tr>
                        )
                    })}
                </tbody>
            </Table>
        </>
    );
}

export default Tabela;