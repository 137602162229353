import { useQuery } from "react-query";
import useAPI from "./useAPI";
import { useMemo } from "react";
import { IConfiguracao } from "../Interfaces";

const useConfiguracao = () => {
    const API = useAPI();

    const qryConfiguracao = useQuery(
        'configuracao',
        () => API.get('configuracao', ''),
        {}
    );

    const dsConfiguracao = useMemo<IConfiguracao>(() => 
        qryConfiguracao.isLoading || qryConfiguracao.isError ?
            {
                "TITULO": "",
                "CPV_RODAPE": "",
                "TXT_WHATSAPP": ""
            }
        :
            qryConfiguracao.data[0]
    , [qryConfiguracao]);

    return {
        dados: dsConfiguracao
    }
}

export default useConfiguracao;