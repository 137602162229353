import { useMemo, useContext, useEffect, useCallback } from "react";
import { Badge, Form } from "react-bootstrap";
import { useMutation, useQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import CtxGerenciador from "../../Contexts/ctxGerenciador";
import useAPI from "../../Hooks/useAPI";
import { IJogo, IPagamento } from "../../Interfaces";
import { ETpFiltro } from "../../Interfaces/enums";

const useDMGerenciador = (idJogo: any, idPessoa : number) => {
    const API = useAPI();
    const ctxGerenciador = useContext(CtxGerenciador);
    const navigate = useNavigate();

    const objDadosJogo = {
        "JOGO": idJogo,
        "PONTEIRO": idPessoa
    };

    const qryVisualizacao = useQuery(
        'visualizacao',
        () => API.get('visualizacao', objDadosJogo),
        {}
    );

    const qryDadosJogo = useQuery(
        'dadosJogo',
        () => API.get('jogo', {"cd": idJogo}),
        {}
    );

    const mutConfirmaAposta = useMutation((data: IPagamento) => API.postArray(
        'pagamento',
        {
            "PAGO":data['PAGO'],
            "CD_PONTEIRO":data['CD_PONTEIRO'],
            "DT_PAGAMENTO":data['DT_PAGAMENTO'],
            "HR_PAGAMENTO":data['HR_PAGAMENTO'],
            "CHAVE":data['CHAVE'],
            "CD_CARTAO":""
        },
            data['CD_CARTAO']
    ));

    const mutExcluirAposta = useMutation((data: any) => API.postArray(
        'cartoesDeletar',
        {"CD_CARTAO":""},
        data['CD_CARTAO']
    ));

    const verComprovante = useCallback((CdPessoa: string, IgnorarFiltro: boolean = false) => {
        ctxGerenciador.setSelecionados([]);

        if (!ctxGerenciador.filtroPagos || IgnorarFiltro) {
            navigate(`/comprovante/${idJogo}/${CdPessoa}`);
        } else {
            window.location.reload();
        };
    }, [idJogo, navigate, ctxGerenciador]);

    useEffect(() => {
        if (mutConfirmaAposta.isSuccess) {
            verComprovante(ctxGerenciador.selecionados[0].CD_PESSOA);
        } else if (mutConfirmaAposta.isError) {
            alert(mutConfirmaAposta.error)
        };

        if (mutExcluirAposta.isSuccess) {
            window.location.reload();
        }
    }, [mutExcluirAposta.isSuccess, mutConfirmaAposta.isSuccess, mutConfirmaAposta.isError, mutConfirmaAposta.error, qryDadosJogo, ctxGerenciador.selecionados, verComprovante])

    const dsVisualizacao = useMemo(() =>
        qryVisualizacao.isLoading || qryVisualizacao.isError ?
            [] 
        : 
            JSON.stringify(ctxGerenciador.selecionados) !== '[]' ?
                qryVisualizacao.data.map((obj : any) => { // Adiciona campo select
                    return {...obj, select: ctxGerenciador.selecionados.some((v: any) => v['CD_CARTAO'] === obj['CD_CARTAO']) }
                }).filter((dado : any) => { // Filtra
                    return(
                        (dado["CD_PESSOA"] === ctxGerenciador.selecionados[0].CD_PESSOA) &&
                        (Boolean(parseInt(dado["PAGO"])) === ctxGerenciador.filtroPagos)
                    )
                })
            :
                qryVisualizacao.data.map((obj : any) => {
                    return {...obj, select: ctxGerenciador.selecionados.some((v: any) => v['CD_CARTAO'] === obj['CD_CARTAO']) }
                }).filter((dado : any) => { // Filtra
                    return(
                        (Boolean(parseInt(dado["PAGO"])) === ctxGerenciador.filtroPagos)
                    )
                }).filter((dado : any) => {
                    if ( (ctxGerenciador.tpFiltro === ETpFiltro.Nome) && (ctxGerenciador.filtro.length > 1) ) {
                        return (dado['NOME'].indexOf(ctxGerenciador.filtro.toUpperCase()) !== -1)
                    } else if (ctxGerenciador.tpFiltro === ETpFiltro.Codigo) {
                        if (ctxGerenciador.filtro.slice(0, idJogo.length) === idJogo) {
                            return (dado["CD_PESSOA"] === ctxGerenciador.filtro.slice(idJogo.length, ctxGerenciador.filtro.length) )
                        } else {
                            return null;
                        }
                    } else {
                        return dado;
                    };  
                })
        , [qryVisualizacao, ctxGerenciador.selecionados, ctxGerenciador.filtroPagos, ctxGerenciador.filtro, ctxGerenciador.tpFiltro, idJogo]
    );

    const dsDadosJogo = useMemo<IJogo>(() => 
        qryDadosJogo.isLoading || qryDadosJogo.isError ?
            {
                "CD_JOGO": "",
                "NOME": "",
                "VALOR_CARTAO": 0,
                "OBS": "",
                "STATUS": "",
                "CONTATO": "",
                "COMISSAO_PONTEIRO": 0
            }
        :
            qryDadosJogo.data
    , [qryDadosJogo]);

    const grdVisualizacao = useMemo(() => [
        {
            id: 'visualizacao',
            Header: 'Apostas',
            columns: [
                {
                    Header: "",
                    accessor: "select",
                    Cell: (row : any) => 
                        <Form.Check 
                            type="checkbox" 
                            disabled={row.row.original['CD_LANCAMENTO'] !== null}
                            className={'cursor-pointer'}
                            checked={row.value} 
                            onChange={() => {
                                ctxGerenciador.setSelecionados((old: any) => {                                        
                                    if (!old.some((v: any) => v['CD_CARTAO'] === row.row.original['CD_CARTAO'])) {
                                        return [...old, row.row.original]
                                    } else {
                                        old.splice(old.indexOf(old.find((v : any) => v['CD_CARTAO'] === row.row.original['CD_CARTAO'])), 1)
                                        return [...old];
                                    }
                                })                                    
                            }}
                        />
                },
                {
                    Header: "Nome",
                    accessor: "NOME"
                },
                {
                    Header: "Números",
                    Cell: (row : any) => 
                        <h5>
                            <Badge pill bg={"light"} text={"dark"}>
                                {row.row.original['N1']}
                            </Badge>
                            <Badge pill bg={"light"} text={"dark"}>
                                {row.row.original['N2']}
                            </Badge>
                            <Badge pill bg={"light"} text={"dark"}>
                                {row.row.original['N3']}
                            </Badge>
                            <Badge pill bg={"light"} text={"dark"}>
                                {row.row.original['N4']}
                            </Badge>
                            <Badge pill bg={"light"} text={"dark"}>
                                {row.row.original['N5']}
                            </Badge>
                            <Badge pill bg={"light"} text={"dark"}>
                                {row.row.original['N6']}
                            </Badge>
                            <Badge pill bg={"light"} text={"dark"}>
                                {row.row.original['N7']}
                            </Badge>
                            <Badge pill bg={"light"} text={"dark"}>
                                {row.row.original['N8']}
                            </Badge>
                            <Badge pill bg={"light"} text={"dark"}>
                                {row.row.original['N9']}
                            </Badge>
                            <Badge pill bg={"light"} text={"dark"}>
                                {row.row.original['N10']}
                            </Badge>
                        </h5>
                },
                {
                    Header: "Pago?",
                    accessor: "PAGO",
                    Cell: (row : any) => 
                        <h5 
                            className="cursor-pointer" 
                            onClick={() => Boolean(parseInt(row.value)) === true ? verComprovante(row.row.original['CD_PESSOA'], true) : null}
                        >
                            {
                                Boolean(parseInt(row.value)) === true ?
                                    '✅'
                                :
                                    '❌'
                            }
                        </h5>
                }
            ]
        }
    ], [ctxGerenciador, verComprovante]);

    return {
        mutConfirmaAposta,
        mutExcluirAposta,
        qryVisualizacao,
        qryDadosJogo,
        dsVisualizacao,
        dsDadosJogo,
        grdVisualizacao
    }
}

export default useDMGerenciador;